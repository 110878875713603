import IssueWithOrder from '@/components/issue-with-order'
import { cn } from '@/utils'
import ChatIcon from '../components/icons/chat-icon'
import FullScreenIcon from '../components/icons/full-screen-icon'
import PrinterIcon from '../components/icons/printer-icon'
import { Order, OrderDelivery } from '../orders/models'
import { getOrderStatusData } from '../orders/utils'
import { useStore } from '../root-store'
import { useAskForHelpFlow } from '../support/help-with-order'
import { getBadgeProps, StatusBadge } from '@/orders/refunds'
import { Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export default function OrderActions({
  order,
  delivery,
  minimized,
  reloadOrder,
  onExpand,
  onClose,
  fromPopup,
}: {
  order: Order
  delivery?: OrderDelivery
  minimized?: boolean
  fromPopup?: boolean
  reloadOrder: () => void
  onClose?: () => void
  onExpand?: () => void
}) {
  const askForHelp = useAskForHelpFlow()
  const { printStore } = useStore()
  const statusData = getOrderStatusData(order, delivery)
  return (
    <div
      className={cn(
        'flex flex-col justify-center border-b border-[#E8E8E8] pb-4 pl-5 pr-2 pt-1',
        minimized && 'flex-row-reverse items-center justify-between pb-1',
      )}
    >
      {fromPopup && (
        <Box
          padding={3}
          display={'flex'}
          justifyContent={'flex-end'}
          className='absolute left-0 top-0'
          onClick={onClose}
        >
          <CloseIcon sx={{ cursor: 'pointer' }} />
        </Box>
      )}
      <div className='flex items-center justify-end'>
        {!minimized && (
          <IssueWithOrder order={order} reloadOrder={reloadOrder} />
        )}
        {!minimized && (
          <button
            className='p-3'
            onClick={(e) => {
              e.stopPropagation()
              askForHelp({ order, reloadOrder })
            }}
          >
            <ChatIcon className='h-6 w-6 text-[#9D9D9D]' />
          </button>
        )}
        {!minimized && (
          <button
            className='p-3'
            onClick={(e) => {
              e.stopPropagation()
              printStore.addToPrintQueue(order, true)
            }}
          >
            <PrinterIcon className='h-6 w-6 text-[#9D9D9D]' />
          </button>
        )}
        {onExpand && (
          <button
            className='p-3'
            onClick={(e) => {
              e.stopPropagation()
              onExpand()
            }}
          >
            <FullScreenIcon className='w-6 text-[#9D9D9D]' />
          </button>
        )}
      </div>
      <div className='flex items-center justify-between'>
        <p className='flex items-baseline gap-1 text-sm font-medium leading-10'>
          <span
            className='inline-block h-2 w-2 shrink-0 rounded-full'
            style={{ backgroundColor: statusData.backgroundColor }}
          ></span>
          {statusData.longDisplayString ?? statusData.displayString}
        </p>
        {(order.refunds?.length ?? 0) > 0 && (
          <div className='mt-1 flex h-8 gap-2'>
            {order.refunds!.map((refund) => (
              <StatusBadge
                key={refund.id}
                {...getBadgeProps(refund.refund_status)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
