import { useRunRequest } from '@/axios-client'
import { CustomTextInput } from '@/components/inputs'
import LoadingButton from '@/components/loading-button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import Wheel from '@/components/wheel'
import { useIsTouchScreen } from '@/utils/hooks'
import { debounce } from 'lodash'
import { DateTime } from 'luxon'
import { useCallback, useMemo, useState } from 'react'
import { Order, VehicleType } from './models'

export default function AcceptScheduledOrderDialog({
  order,
  submit,
}: {
  order: Order
  submit: (params: Record<string, unknown>) => Promise<void>
}) {
  const [open, setOpen] = useState(true)
  const scheduledPickupTime = useMemo(() => {
    if (!order.is_scheduled) {
      return null
    }

    if (order.customer_requested_pickup_time) {
      return DateTime.fromISO(order.customer_requested_pickup_time)
    } else if (order.customer_requested_dropoff_time) {
      return DateTime.fromISO(order.customer_requested_dropoff_time).minus({
        minutes: 15,
      })
    }

    return null
  }, [
    order.customer_requested_dropoff_time,
    order.customer_requested_pickup_time,
    order.is_scheduled,
  ])
  const [prepTime, setPrepTime] = useState(order.store!.default_prep_time ?? 0)
  const isTouchScreen = useIsTouchScreen()
  const runRequest = useRunRequest()

  const onWheelChange = useCallback(
    debounce((idx: number) => setPrepTime(idx), 200),
    [],
  )

  const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault()

      const scheduledPrepareTime = scheduledPickupTime!.minus({
        minutes: prepTime,
      })

      return runRequest.action({
        action: async () => {
          await submit({
            scheduled_prepare_time: scheduledPrepareTime.toUTC().toISO(),
            delivery: order.is_pickup
              ? undefined
              : {
                  pickup_time: scheduledPickupTime!.toUTC().toISO(),
                  vehicle_type: VehicleType.Car,
                  schedule: order.is_scheduled,
                },
          })
          setOpen(false)
        },
        defaultError: 'Error accepting scheduled order',
      })
    },
    [order.is_pickup, prepTime, runRequest, scheduledPickupTime, submit],
  )

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className='max-w-2xl gap-10'>
        <DialogHeader>
          <DialogTitle className='leading-normal'>
            Scheduled Order for {scheduledPickupTime!.toFormat('h:mm a')}
          </DialogTitle>
          {scheduledPickupTime && (
            <DialogDescription className='mt-2.5 text-xs leading-normal'>
              We&apos;ll move this order to the{' '}
              <span className='font-bold'>In Process</span> tab 5 minutes before
              prep is required.
            </DialogDescription>
          )}
        </DialogHeader>
        <form onSubmit={onSubmit}>
          {isTouchScreen ? (
            <div className='relative flex h-60 items-center justify-center text-[#16191C]'>
              <p className='absolute left-0 top-1/2 -translate-y-1/2 text-[#9D9D9D]'>
                Adjust prep time
              </p>
              <Wheel
                initIdx={prepTime}
                length={61}
                width={23}
                label='min'
                setValue={(idx) => idx.toString()}
                onChange={onWheelChange}
              />
              <p className='absolute right-0 top-1/2 -translate-y-1/2 text-[#9D9D9D]'>
                Ready at {scheduledPickupTime!.toFormat('h:mm a')}
              </p>
            </div>
          ) : (
            <CustomTextInput
              type='number'
              label='Adjust prep time'
              required
              size='small'
              value={prepTime}
              onChange={(value) => setPrepTime(value)}
              fullWidth
            />
          )}
          <LoadingButton
            loading={runRequest.loading}
            className='mt-10 w-full rounded-lg bg-scheduled p-4 text-sm font-semibold leading-normal'
          >
            Confirm Scheduled Order
          </LoadingButton>
        </form>
      </DialogContent>
    </Dialog>
  )
}
