import { useRunRequest } from '@/axios-client'
import LoadingButton from '@/components/loading-button'
import { AutocompleteAddressWrapper } from '@/components/new-autocomplete-address'
import Spinner from '@/components/spinner'
import { Button } from '@/components/ui/button'
import FormInput from '@/components/ui/form-input'
import FormSelect from '@/components/ui/form-select'
import { SelectItem } from '@/components/ui/select'
import { NewOrderAlertVolume } from '@/orders/models'
import { zodResolver } from '@hookform/resolvers/zod'
import { startCase } from 'lodash'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { useStore } from '../root-store'
import { StoreModel } from './models'
import { EditStoreSchema, storesService } from './store-service'
import { Grid, Box } from '@mui/material'
import { Page } from '@/utils/page'
import { Section } from '@/orders/utils'
import { BreakpointsSelector } from '@/utils/mobile'
import { cn } from '@/utils'

function EditStore({ store }: { store: StoreModel }) {
  const { authStore } = useStore()
  const runRequest = useRunRequest()
  const form = useForm<z.infer<typeof EditStoreSchema>>({
    resolver: zodResolver(EditStoreSchema),
    defaultValues: {
      ...store,
    },
  })

  useEffect(() => {
    form.reset(store)
  }, [store])

  const handleSubmit: SubmitHandler<z.infer<typeof EditStoreSchema>> =
    useCallback(
      (params) => {
        return runRequest.action({
          defaultError: 'Error updating store',
          action: async () => {
            await authStore.updateStore(store.id, params)
          },
        })
      },
      [authStore, runRequest, store.id],
    )

  const subiTitleStyle = { fontFamily: 'Poppins', fontWeight: 400 }

  return (
    <Section
      padding={5}
      gap={3}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Grid container>
          <Grid item sm={6} md={8}>
            <Box
              sx={{ fontFamily: 'Poppins', fontSize: 24, fontWeight: '500' }}
            >
              Store Settings
            </Box>
          </Grid>
          <BreakpointsSelector
            sm={
              <Grid
                display={'flex'}
                justifyContent={'end'}
                paddingRight={4}
                item
                xs={6}
                md={4}
              >
                <LoadingButton
                  loading={runRequest.loading}
                  component={Button}
                  className='bg-[#66DAF1] text-black hover:bg-[#00C2E8]'
                  type='submit'
                  style={{
                    fontSize: '16px',
                    paddingInline: 32,
                    borderRadius: '10px',
                  }}
                >
                  Update Store
                </LoadingButton>
              </Grid>
            }
          ></BreakpointsSelector>
        </Grid>
        <Grid container spacing={8} paddingBlock={8}>
          <Grid item style={subiTitleStyle} xs={12}>
            <h2>General Info</h2>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormInput
              control={form.control}
              name='name'
              placeholder='Name *'
              required
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <AutocompleteAddressWrapper
              label='Address *'
              control={form.control}
              name='location'
              required
            />
          </Grid>

          <Grid style={subiTitleStyle} item xs={12}>
            <h2>Delivery Settings</h2>
          </Grid>
          <Grid item xs={12}>
            <FormInput
              name='default_courier_pickup_instructions'
              control={form.control}
              placeholder='Default Courier Pickup Instructions'
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FormInput
              type='number'
              control={form.control}
              name='default_prep_time'
              placeholder='Default Prep Time (minutes)'
              required
            />
          </Grid>

          <Grid style={subiTitleStyle} item xs={12}>
            <h2>Order Settings</h2>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormSelect
              placeholder='New Order Alert Volume *'
              name='new_order_alert_volume'
              control={form.control}
              triggerClassName='focus:ring-transparent focus:ring-0'
            >
              {Object.values(NewOrderAlertVolume).map((value) => (
                <SelectItem key={value} value={value}>
                  {startCase(value.toLowerCase().replace('_', ' '))}
                </SelectItem>
              ))}{' '}
            </FormSelect>
          </Grid>
          <BreakpointsSelector
            sm={
              <Grid
                display={'flex'}
                justifyContent={'start'}
                item
                width={'90vw'}
                xs={12}
              >
                <LoadingButton
                  className={cn(
                    'w-full rounded text-center',
                    'bg-[#66DAF1] text-black hover:bg-[#00C2E8]',
                  )}
                  loading={runRequest.loading}
                  component={Button}
                  type='submit'
                >
                  Update Store
                </LoadingButton>
              </Grid>
            }
            direction='down'
          ></BreakpointsSelector>
        </Grid>
      </form>
    </Section>
  )
}

const StoreSettingsPage = observer(() => {
  const { authStore } = useStore()
  const [store, setStore] = useState<StoreModel>()

  useEffect(() => {
    if (authStore.currentUserStore?.store_id) {
      storesService
        .getStore(authStore.currentUserStore?.store_id)
        .then((res) => setStore(res.data.store))
    } else {
      authStore.getCurrentStore(true).then((store) => {
        if (store) {
          setStore(store)
        }
      })
    }
  }, [authStore.currentUserStore?.store_id])

  return (
    <Page title='Store Settings'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          paddingTop: 3,
          paddingBottom: 14,
          alignItems: 'stretch',
        }}
      >
        {store ? (
          <EditStore store={store} />
        ) : (
          <div className='flex flex-1 items-center justify-center'>
            <Spinner className='w-8' />
          </div>
        )}
      </Box>
    </Page>
  )
})
export default StoreSettingsPage
