import { cn } from '@/utils'
import { useEffect, useMemo, useRef } from 'react'
import { createPortal } from 'react-dom'
import { LiveOrder, NewOrderAlertVolume } from './models'
import { useOrderPopup } from '@/order-details/hooks'

export const OrderAlertModal = ({
  orders,
  text,
  subText,
  subTextColor,
  backgroundColor,
  clearOrders,
  orderAlertVolume,
  delayTime,
  sound,
}: {
  orders: LiveOrder[]
  text: string
  subText: string
  subTextColor: string
  backgroundColor: string
  clearOrders: (orderId: string) => void
  orderAlertVolume: NewOrderAlertVolume
  delayTime?: string
  sound: string
}) => {
  const { toggleOpenOrder } = useOrderPopup()

  const count = useMemo(() => orders.length, [orders.length])

  const audioRef = useRef<HTMLAudioElement>(null)

  const closeModal = () => {
    if (audioRef.current) {
      audioRef.current.muted = true
    }
    if (count === 1) {
      toggleOpenOrder(orders[0].id)
    }

    clearOrders(orders[0].id)
  }

  useEffect(() => {
    const handleClick = () => {
      if (audioRef.current?.paused && document.contains(audioRef.current)) {
        audioRef.current.play()
      }
    }
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  useEffect(() => {
    if (count > 0) {
      if (audioRef.current) {
        if (audioRef.current.paused && document.contains(audioRef.current)) {
          audioRef.current.play()
        } else {
          audioRef.current.currentTime = 0
        }

        audioRef.current.muted = false
        audioRef.current.volume =
          (orderAlertVolume === 'QUIET' ? 0.5 : 1) * (delayTime ? 0.25 : 1)
      }

      const timeout = setTimeout(() => {
        if (audioRef.current) {
          const handler = () => {
            if (
              audioRef.current &&
              audioRef.current.currentTime >= audioRef.current.duration - 0.1
            ) {
              audioRef.current.muted = true
              audioRef.current.removeEventListener('timeupdate', handler)
            }
          }

          audioRef.current.addEventListener('timeupdate', handler)
        }
      }, 30_000)

      return () => clearTimeout(timeout)
    }
  }, [count, orderAlertVolume])

  return createPortal(
    <>
      <audio ref={audioRef} src={sound} loop muted></audio>
      {count > 0 && (
        <div className='fixed inset-0 bg-black/80 p-12 font-[Poppins,sans-serif]'>
          <div
            className={cn(
              'flex h-full w-full flex-col items-center justify-between gap-10 overflow-hidden rounded-lg p-10',
              backgroundColor,
            )}
          >
            <div className='z-0 flex flex-1 flex-col items-center justify-center text-center'>
              <div className='relative mb-8 flex h-64 w-64 flex-col items-center justify-center rounded-full bg-[#F2F2F2] before:absolute before:inset-0 before:-z-10 before:animate-ping before:rounded-[inherit] before:bg-inherit'>
                {delayTime ? (
                  <p
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'end',
                      paddingLeft: '20px',
                    }}
                  >
                    <p className='text-6xl'>{Number(delayTime)}</p>
                    <p>min</p>
                  </p>
                ) : (
                  <p className='text-9xl'>{count}</p>
                )}

                <p className='flex items-center gap-1.5'>{text}</p>
              </div>
              <p
                style={{ fontWeight: delayTime ? 600 : 'normal' }}
                className={cn('text-xl', subTextColor)}
              >
                {subText}
              </p>
            </div>
            <button
              className='z-10 rounded-lg bg-white px-40 py-6 text-sm font-semibold text-black'
              onClick={closeModal}
              style={{
                width: '50vw',
                maxWidth: '40rem',
                padding: '1.5rem 4rem',
              }}
            >
              View Order
              {count === 1 ? '' : 's'}
            </button>
          </div>
        </div>
      )}
    </>,
    document.body,
  )
}
