import bgMobile from '@/assets/bg-mobile.png'
import bg from '@/assets/bg.png'
import { AuthState } from '@/auth/auth-store'
import { auth } from '@/auth/firebase'
import { axiosClient, useRunRequest } from '@/axios-client'
import { useStore } from '@/root-store'
import { StoreType } from '@/stores/models'
import { cn } from '@/utils'
import { PHONE_REGEX } from '@/utils/general'
import { zodResolver } from '@hookform/resolvers/zod'
import { startCase } from 'lodash'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import LoadingButton from './loading-button'
import Logo from './logo'
import {
  AutocompleteAddressWrapper,
  locationSchema,
} from './new-autocomplete-address'
import { Button } from './ui/button'
import FormInput from './ui/form-input'
import FormSelect from './ui/form-select'
import { SelectItem } from './ui/select'

const onboardingSchema = z.object({
  store_type: z.nativeEnum(StoreType),
  store_name: z.string(),
  store_location: locationSchema,
  contact_name: z.string(),
  contact_email: z.string().email(),
  contact_phone_number: z.string().regex(PHONE_REGEX, 'Invalid phone number'),
})

const Form = ({
  className,
  referrerId,
}: {
  className?: string
  referrerId?: string
}) => {
  const navigate = useNavigate()
  const form = useForm<z.infer<typeof onboardingSchema>>({
    resolver: zodResolver(onboardingSchema),
  })

  const runRequest = useRunRequest()
  const handleSubmit: SubmitHandler<z.infer<typeof onboardingSchema>> =
    useCallback(
      (values) => {
        return runRequest.action({
          async action() {
            await auth.signInAnonymously()
            return new Promise<void>((resolve, reject) => {
              const unsubscribe = auth.onAuthStateChanged(
                async () => {
                  try {
                    unsubscribe()

                    const response = await axiosClient.post<{
                      customToken: string
                    }>('/onboarding', { ...values, referrer_id: referrerId })

                    await auth.signInWithCustomToken(response.data.customToken)

                    window.gtag?.('event', 'onboarding_submitted')

                    resolve()
                    navigate('success')
                  } catch (error) {
                    reject(error)
                  }
                },
                (error) => reject(error),
              )
            })
          },
        })
      },
      [navigate, referrerId, runRequest],
    )

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      className={cn(
        'flex w-full flex-col gap-6 self-start justify-self-end rounded-lg md:max-w-md md:bg-white md:px-9 md:py-7 md:shadow-[0_0_14px_0_rgba(0,0,0,.1)]',
        className,
      )}
    >
      <p className='text-center font-bold'>
        Fill out your store info to get started
      </p>

      <FormSelect
        control={form.control}
        name='store_type'
        placeholder='What type of business do you operate? *'
      >
        {Object.values(StoreType).map((value) => (
          <SelectItem key={value} value={value}>
            {startCase(value.toLowerCase().replace('_', ' '))}
          </SelectItem>
        ))}
      </FormSelect>

      <FormInput
        control={form.control}
        type='text'
        name='store_name'
        placeholder='What is your store name? *'
        required
      />

      <AutocompleteAddressWrapper
        label='What is your store address? *'
        control={form.control}
        name='store_location'
        required
        isOnboarding
      />

      <FormInput
        control={form.control}
        type='text'
        name='contact_name'
        placeholder='What is your name? *'
        required
      />

      <FormInput
        control={form.control}
        type='text'
        name='contact_email'
        placeholder='What is your email? *'
        required
      />

      <FormInput
        control={form.control}
        type='text'
        name='contact_phone_number'
        placeholder='What is your phone number? *'
        mask='phone'
        required
      />

      <LoadingButton
        loading={runRequest.loading}
        component={Button}
        className='bg-[#00C2E8] hover:bg-[#00aacc]'
      >
        <span className='max-md:hidden'>Submit</span>
        <span className='md:hidden'>Get started with a2b</span>
      </LoadingButton>
    </form>
  )
}

const Onboarding = observer(() => {
  const navigate = useNavigate()
  const { authStore } = useStore()
  const [referToken, setReferToken] = useState<string>('')
  const [refer, setRefer] = useState<{
    storeId: string
    storeName: string
  } | null>(null)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const ref = queryParams.get('ref')
    setReferToken(ref || '')
  }, [])

  useEffect(() => {
    if (referToken) {
      const verifyReferral = async () => {
        try {
          const res = await axiosClient.get('/onboarding/verify-referrer', {
            params: { ref: referToken },
          })
          setRefer(res.data)
        } catch (error) {
          setReferToken('')
        }
      }
      verifyReferral()
    }
  }, [referToken])

  useEffect(() => {
    if (authStore.authState === AuthState.SIGNED_IN) {
      navigate('/')
    }
  }, [authStore.authState, navigate])

  return (
    <div className='flex min-h-screen flex-col'>
      <div
        className='bg-white bg-[image:var(--bg-mobile)] bg-cover bg-no-repeat py-6 max-md:pt-8 md:bg-[image:var(--bg)] md:bg-contain md:bg-right'
        style={
          { '--bg': `url(${bg})`, '--bg-mobile': `url(${bgMobile})` } as any
        }
      >
        <div className='container mx-auto px-4'>
          <div className='flex flex-col items-start gap-4 max-md:items-center'>
            <Logo className='w-16 max-md:text-[#2C2C2D]' />
            <p className='text-2xl/normal font-medium max-md:text-center md:text-[2.5rem]/[1.3] md:font-bold'>
              Expand Your Delivery
              <br />
              Capabilities with a2b
            </p>
            {refer?.storeName && (
              <div>
                Referred by{' '}
                <span className='text-[#00C2E8]'>{refer.storeName}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='flex-1 bg-white md:bg-[#EBFCFF] md:shadow-[0_-1px_11px_0_rgba(235,235,235,.25)]'>
        <div className='container mx-auto grid-cols-2 gap-10 px-4 py-10 md:grid min-[854px]:max-lg:max-w-[90vw]'>
          <div className='text-sm/normal md:max-w-lg md:text-lg/relaxed'>
            <p className='font-bold max-md:text-center max-sm:mb-1'>
              Welcome to a2b Delivery!
            </p>
            {refer?.storeName ? (
              <p className='mb-4 font-bold max-md:text-center max-sm:mb-1'>
                {refer.storeName} trusts a2b Delivery to streamline and optimize
                their delivery service.
              </p>
            ) : (
              <p className='mb-10 max-md:text-center md:mb-6'>
                Restaurants across the country trust a2b Delivery to lower
                third-party delivery fees, boost profits per order, and provide
                reliable, on-demand drivers to ensure fast and seamless
                deliveries. Take control of your delivery service with a2b!
              </p>
            )}

            <Form className='mb-10 md:hidden' referrerId={refer?.storeId} />

            <p className='font-bold max-sm:mb-1'>Why Choose a2b Delivery?</p>
            <p className='mb-6'>
              On-Demand Drivers, Anytime You Need Them.
              <br />
              a2b offers immediate access to reliable, on-demand drivers,
              ensuring your customers enjoy prompt deliveries with minimal wait
              times. Say goodbye to lost orders and dissatisfied customers
              caused by understaffing—we’ve got you covered.
              <span className='text-[#00C2E8] md:hidden'>
                {' '}
                Fill out the form to deliver orders to your customers within
                minutes.
              </span>
            </p>

            <p className='font-bold max-sm:mb-1'>Save Big on App Orders</p>
            <p className='md:mb-6'>
              a2b helps you cut down on costly third-party delivery fees,
              boosting your profit margins on every delivery order. Keep more of
              what you earn while offering a seamless service to your customers.
            </p>

            <p className='font-bold text-[#00C2E8] max-sm:hidden'>
              Start delivering Orders Now!
            </p>
            <p className='max-sm:hidden'>
              Fill out the form on the right and you will be able to use our
              platform to deliver orders to your customers within minutes.
            </p>
          </div>

          <Form className='max-md:hidden' referrerId={refer?.storeId} />
        </div>
      </div>
    </div>
  )
})

export default Onboarding
