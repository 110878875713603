import { useRunRequest } from '@/axios-client'
import LoadingButton from '@/components/loading-button'
import { AutocompleteAddressWrapper } from '@/components/new-autocomplete-address'
import Spinner from '@/components/spinner'
import { Button } from '@/components/ui/button'
import FormDayTimePicker from '@/components/ui/form-day-time-picker'
import FormInput from '@/components/ui/form-input'
import FormSelect from '@/components/ui/form-select'
import FormSwitch from '@/components/ui/form-switch'
import { SelectItem } from '@/components/ui/select'
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet'
import Wheel from '@/components/wheel'
import { AsyncDialogBaseProps } from '@/dialogs/models'
import { useStore } from '@/root-store'
import { StoreModel } from '@/stores/models'
import { useIsTouchScreen } from '@/utils/hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import { Crisp } from 'crisp-sdk-web'
import { debounce, kebabCase } from 'lodash'
import { Trash2 } from 'lucide-react'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { OrderItemSize } from './models'
import { ordersService } from './order-service'
import OutOfFreeDeliveries from './out-of-free-deliveries'
import { CreateOrderFields, CreateOrderSchema } from './schema'
import { useDefaultDeliveryValues } from './utils'
import { v4 as uuid } from 'uuid'

function CreateOrderSheet({
  store,
  resolve,
}: {
  store: StoreModel
  resolve: () => void
}) {
  const isTouchScreen = useIsTouchScreen()
  const defaultDeliveryValues = useDefaultDeliveryValues({ store })
  const runRequest = useRunRequest()
  const form = useForm<CreateOrderFields>({
    resolver: zodResolver(CreateOrderSchema),
    defaultValues: {
      store_id: store.id,
      contactless_dropoff: true,
      is_pickup: false,
      courier_pickup_instructions: store.default_courier_pickup_instructions,
      external_id: uuid().slice(-8).toUpperCase(),
      ...defaultDeliveryValues,
    },
  })

  const items = useFieldArray({
    control: form.control,
    name: 'items',
  })

  const schedule = form.watch('delivery.schedule')
  const isSelfDelivery = form.watch('delivery.self_delivery')
  const pickupDelayMinutes = form.watch('delivery.pickup_delay_minutes')

  const onWheelChange = useCallback(
    debounce((idx) => form.setValue('delivery.pickup_delay_minutes', idx), 200),
    [form],
  )

  const handleSubmit: SubmitHandler<CreateOrderFields> = useCallback(
    (params) => {
      return runRequest.action({
        defaultError: 'Error creating order',
        async action() {
          const items = params.items?.map((item, index) => ({
            ...item,
            id: index.toString(),
          }))

          await ordersService.createOrderAndDelivery({
            ...params,
            items,
            customer_address: params.customer_address.display_string,
          })

          resolve()
        },
      })
    },
    [resolve, runRequest],
  )

  useEffect(() => {
    Crisp.chat.hide()
    return () => Crisp.chat.show()
  })

  return (
    <>
      <SheetHeader className='mt-10'>
        <SheetTitle className='text-/normal text-center font-medium'>
          Start an Order
        </SheetTitle>
      </SheetHeader>

      <form className='flex h-full flex-col overflow-hidden'>
        <div className='mt-5 flex flex-1 flex-col gap-5 overflow-auto px-5 pb-10 pt-5'>
          <div>
            <FormInput
              control={form.control}
              name='external_id'
              placeholder='Order ID *'
              required
            />
          </div>

          <div className='flex gap-4'>
            <div className='flex-1'>
              <AutocompleteAddressWrapper
                label='Customer Address *'
                control={form.control}
                name='customer_address'
                required
              />
            </div>
            <label className='flex cursor-pointer select-none items-center justify-between gap-4 text-sm/normal'>
              Contactless Delivery
              <FormSwitch control={form.control} name='contactless_dropoff' />
            </label>
          </div>

          <div className='flex gap-4'>
            <FormInput
              control={form.control}
              name='customer_name'
              placeholder='Customer Name *'
              required
            />
            <FormInput
              control={form.control}
              name='customer_phone'
              placeholder='Customer Phone *'
              mask='phone'
              required
            />
          </div>

          <div>
            <FormInput
              control={form.control}
              name='courier_pickup_instructions'
              placeholder='Pickup Instructions'
            />
          </div>

          <div>
            <FormInput
              control={form.control}
              name='dropoff_instructions'
              placeholder='Dropoff Instructions'
            />
          </div>

          {store.dropoff_notifications_enabled && (
            <div className='self-start'>
              <label className='flex cursor-pointer select-none items-center gap-4 text-sm/normal'>
                Contact Customer upon Dropoff
                <FormSwitch
                  control={form.control}
                  name='dropoff_notification_enabled'
                />
              </label>
            </div>
          )}

          <label className='flex cursor-pointer select-none items-center gap-4 self-start text-sm/normal'>
            Schedule
            <FormSwitch control={form.control} name='delivery.schedule' />
          </label>

          {schedule && (
            <div className='flex-1'>
              <FormDayTimePicker
                control={form.control}
                name='delivery.pickup_time'
                disablePast
                containerClassName='flex-row'
              />
            </div>
          )}

          {!schedule &&
            (isTouchScreen ? (
              <div className=''>
                <div className='relative flex h-60 items-center justify-center text-[#16191C]'>
                  <p className='absolute left-0 top-1/2 -translate-y-1/2 text-[#9D9D9D]'>
                    Adjust prep time
                  </p>
                  <Wheel
                    initIdx={pickupDelayMinutes ?? 0}
                    length={61}
                    width={23}
                    label='min'
                    setValue={(idx) => idx.toString()}
                    onChange={onWheelChange}
                  />
                  <p className='absolute right-0 top-1/2 -translate-y-1/2 text-[#9D9D9D]'>
                    Ready at{' '}
                    {DateTime.now()
                      .plus({ minutes: pickupDelayMinutes })
                      .toFormat('h:mm a')}
                  </p>
                </div>
              </div>
            ) : (
              <div className='flex flex-wrap items-center gap-4'>
                <p className='shrink-0 text-sm/normal'>Adjust Prep Time</p>
                <div className='w-44'>
                  <FormInput
                    type='number'
                    control={form.control}
                    name='delivery.pickup_delay_minutes'
                    suffix='Min'
                    suffixClassName='text-label ml-2'
                  />
                </div>
                <p className='shrink-0 text-sm/normal text-[#9D9D9D]'>
                  Ready at{' '}
                  {DateTime.now()
                    .plus({ minutes: pickupDelayMinutes })
                    .toFormat('h:mm a')}
                </p>
              </div>
            ))}

          <p className='text-sm/normal'>Add Items</p>

          {items.fields.map((field, index) => (
            <div
              key={index}
              className='grid grid-cols-[repeat(4,minmax(0,1fr))1.25rem] gap-4'
            >
              <FormInput
                type='number'
                control={form.control}
                name={`items.${index}.quantity`}
                placeholder='QTY *'
                required
              />
              <FormInput
                control={form.control}
                name={`items.${index}.name`}
                placeholder='Name *'
                required
              />
              <FormSelect
                control={form.control}
                name={`items.${index}.size`}
                placeholder='Size *'
                triggerClassName='h-auto [&_span]:block [&_span]:overflow-hidden capitalize [&_span]:text-ellipsis [&_span]:whitespace-nowrap'
                required
              >
                {Object.entries(OrderItemSize).map(([key, value]) => (
                  <SelectItem key={key} value={value} className='capitalize'>
                    {kebabCase(key)}
                  </SelectItem>
                ))}
              </FormSelect>
              <FormInput
                type='number'
                control={form.control}
                name={`items.${index}.unit_price`}
                placeholder='Unit Price *'
                prefix='$'
                required
              />
              <button
                type='button'
                className='-ml-2 pl-2 text-[#5F6368]'
                onClick={() => items.remove(index)}
              >
                <Trash2 className='h-5 w-5' />
              </button>
            </div>
          ))}

          <button
            type='button'
            className='flex items-center gap-2 self-start text-xs/normal text-a2b-blue'
            onClick={() =>
              items.append({
                quantity: 1,
                size: OrderItemSize.Small,
                name: '',
                unit_price: 0,
              })
            }
          >
            + Add Item
          </button>

          <FormInput
            control={form.control}
            name='order_special_instructions'
            placeholder='Special Instruction'
          />
        </div>

        <SheetFooter className='border-t border-[#D8D8D8] p-5'>
          <LoadingButton
            component={Button}
            loading={runRequest.loading}
            disabled={!form.formState.isValid || form.formState.isSubmitting}
            size='xl'
            className='w-full bg-[#00C2E8] hover:bg-[#00aacc]'
            onClick={form.handleSubmit(handleSubmit)}
          >
            Create Order and{' '}
            {isSelfDelivery ? 'Self Deliver' : 'Request Courier'}
          </LoadingButton>
        </SheetFooter>
      </form>
    </>
  )
}

export default function CreateOrderDialog({
  open,
  resolve,
  reject,
}: AsyncDialogBaseProps) {
  const { authStore } = useStore()
  const [store, setStore] = useState<StoreModel>()

  useEffect(() => {
    authStore.getCurrentStore(true).then((store) => store && setStore(store))
  }, [authStore])

  if (
    store &&
    !store.has_payment_method &&
    store.free_deliveries_remaining === 0
  ) {
    return <OutOfFreeDeliveries open={open} reject={reject} />
  }

  return (
    <Sheet open={open} onOpenChange={reject}>
      <SheetContent className='flex w-screen flex-col p-0 sm:max-w-xl'>
        {store ? (
          <CreateOrderSheet store={store} resolve={resolve} />
        ) : (
          <div className='flex flex-1 items-center justify-center'>
            <Spinner className='w-8' />
          </div>
        )}
      </SheetContent>
    </Sheet>
  )
}
