import { styled } from '@mui/system'
import {
  Avatar,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { Menu as MenuIcon, OpenInFull } from '@mui/icons-material'
import { observer } from 'mobx-react'
import { useStore } from '../root-store'
import { AutocompleteOrders } from '../orders/autocomplete-orders'
import { useOrderPopup } from '../order-details/hooks'
import { BreakpointsSelector } from '../utils/mobile'
import Logo from '/logo.svg'
import { NavigationMenu, SIDEBAR_WIDTH } from './navigation-menu'
import { useState } from 'react'
import { StoreSelector } from '../components/autocomplets/store-selector'
import { colors } from '@/theme'
import CreateOrderDialog from '@/orders/create-order-dialog'

const isFullScreen = () => {
  const doc = document as any
  return (
    doc.fullscreenElement ||
    doc.mozFullScreenElement ||
    doc.webkitFullscreenElement ||
    doc.msFullscreenElement
  )
}

const fullScreen = () => {
  const doc = document.documentElement as any

  if (doc.requestFullscreen) {
    doc
      .requestFullscreen()
      .catch((err: any) =>
        console.error(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`,
        ),
      )
  } else if (doc.mozRequestFullScreen) {
    // Firefox
    doc.mozRequestFullScreen()
  } else if (doc.webkitRequestFullscreen) {
    // Chrome, Safari, and Opera
    doc.webkitRequestFullscreen()
  } else if (doc.msRequestFullscreen) {
    // IE/Edge
    doc.msRequestFullscreen()
  }
}

const exitFullScreen = () => {
  const doc = document as any
  if (doc.exitFullscreen) {
    doc
      .exitFullscreen()
      .catch((err: any) =>
        console.error(
          `Error attempting to exit full-screen mode: ${err.message} (${err.name})`,
        ),
      )
  } else if (doc.mozCancelFullScreen) {
    // Firefox
    doc.mozCancelFullScreen()
  } else if (doc.webkitExitFullscreen) {
    // Chrome, Safari, and Opera
    doc.webkitExitFullscreen()
  } else if (doc.msExitFullscreen) {
    // IE/Edge
    doc.msExitFullscreen()
  }
}

export const AppBar = () => {
  const {
    authStore: { externalCustomer },
    dialogStore,
  } = useStore()
  const { toggleOpenOrder } = useOrderPopup()
  const imgEl = <img src={Logo} style={{ height: '100%', width: 'auto' }} />
  const [open, setOpen] = useState(false)
  return (
    <>
      <AppBarStyled
        container
        spacing={2}
        $externalCustomer={!!externalCustomer}
      >
        <Grid item xs={12} md={6} sm={12} display='flex' alignItems='center'>
          <BreakpointsSelector
            xl={
              <IconButton size='large' onClick={() => setOpen(!open)}>
                <MenuIcon fontSize='large' style={{ color: 'white' }} />
              </IconButton>
            }
            direction='down'
          />
          <BreakpointsSelector
            xl={
              <Box
                sx={{
                  width: SIDEBAR_WIDTH,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {imgEl}
              </Box>
            }
          />
          <UserStoreStyled>
            <UserStore />
          </UserStoreStyled>

          {externalCustomer && (
            <Grid display='flex' alignItems='center' gap={4} paddingLeft={8}>
              {!!externalCustomer.logo_url && (
                <img src={externalCustomer.logo_url} />
              )}
              <Typography variant='h6' color='white'>
                {externalCustomer.name}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Actions item xs={12} md={6} sm={12}>
          <Box
            display='flex'
            alignItems='center'
            gap={2}
            justifyContent={{ md: 'flex-end' }}
            width='100%'
          >
            <Button
              style={{
                padding: '8px 30px',
                color: externalCustomer ? colors.green.G00 : 'black',
                backgroundColor: colors.dark.D80,
                fontSize: 16,
                width: 'fit-content',
              }}
              className='max-sm:!hidden'
              color='secondary'
              variant='contained'
              fullWidth={true}
              onClick={() => dialogStore.showDialogAsync(CreateOrderDialog)}
            >
              <div>Create Delivery</div>
            </Button>
            <Box
              sx={{
                maxWidth: 400,
                flex: 1,
              }}
            >
              <AutocompleteOrders
                label='Search...'
                onChange={(order) => {
                  if (order) {
                    toggleOpenOrder(order.id)
                  }
                }}
              />
            </Box>
            {!window.AndroidInterface && (
              <IconButton
                onClick={() =>
                  isFullScreen() ? exitFullScreen() : fullScreen()
                }
              >
                <Tooltip title='Full Screen'>
                  <OpenInFull style={{ color: 'white' }} />
                </Tooltip>
              </IconButton>
            )}
          </Box>
        </Actions>
      </AppBarStyled>
      <BreakpointsSelector
        xl={
          <Drawer open={open} anchor='left'>
            <NavigationMenu onClick={() => setOpen(false)} />
          </Drawer>
        }
        direction='down'
      />
    </>
  )
}

const UserStoreStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    maxWidth: 500,
  },
}))

const AppBarStyled = styled(Grid, {
  shouldForwardProp: (prop: string) => !prop.startsWith('$'),
})<{ $externalCustomer?: boolean }>(({ theme, $externalCustomer }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  backgroundColor: $externalCustomer
    ? colors.green.G00
    : theme.palette.primary.main,
  [theme.breakpoints.only('xl')]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
}))

const UserStore = observer(() => {
  const { authStore } = useStore()

  if (!authStore.currentUserStore) {
    return null
  }

  return (
    <Box display='flex' gap={2} alignItems='center' flex={1} overflow='hidden'>
      <Avatar sx={{ bgcolor: 'red' }}>
        {authStore.currentUserStore.store_name.slice(0, 1).toUpperCase()}
      </Avatar>
      <Box sx={{ flex: 1, display: 'flex', overflow: 'hidden' }}>
        <StoreSelector
          onChange={(id) => {
            authStore.switchStore(id)
          }}
          value={authStore.currentUserStore.store_id}
        />
      </Box>
    </Box>
  )
})

const Actions = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  overflow: 'hidden',
  paddingRight: theme.spacing(3),
  width: '100%',

  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(4),
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    gap: theme.spacing(2),
  },
}))
