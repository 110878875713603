import { useMemo } from 'react'
import { useStore } from '../../root-store'
import { Box, MenuItem, Select } from '@mui/material'
import { toDictionary } from '../../utils/array-utils'

export function StoreSelector({
  value,
  onChange,
}: {
  value?: string
  onChange: (storeId: string | null) => void
}) {
  const { authStore } = useStore()

  const options = useMemo(
    () =>
      (authStore.userStores || []).map((s) => ({
        value: s.store_name,
        key: s.store_id,
      })),
    [authStore.claims],
  )
  const keyToValue = useMemo(() => {
    return toDictionary(
      options,
      (o) => o.key,
      (o) => o.value,
    )
  }, [options])

  return (
    <Select
      sx={{
        background: 'rgba(255,255,255,0.8)!important',
        overflow: 'hidden',
        width: '300px',
      }}
      variant='outlined'
      value={value}
      size='small'
      renderValue={(val) => {
        return keyToValue[val]
      }}
      label='Switch Store'
      onChange={(e) => {
        onChange(e.target.value as string)
      }}
    >
      {options.map((o) => (
        <MenuItem key={o.key} value={o.key}>
          <Box display='flex' alignItems='center' flex={1}>
            <Box flex={1}>{o.value}</Box>
          </Box>
        </MenuItem>
      ))}
    </Select>
  )
}
