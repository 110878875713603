import { Box, Button, styled, Typography } from '@mui/material'
import { useMemo } from 'react'
import { ActionsComponent } from '../live-orders/order-actions'
import { OrderItems } from '../live-orders/order-items'
import { OrderSpecialInstructions } from '../live-orders/review-orders-dialog'
import {
  DeliveryStatus,
  Order,
  OrderDelivery,
  OrderDetails,
  PICKED_UP_STATUSES,
} from '../orders/models'
import { BasicOrderInfo } from './basic-order-info'
import OrderActions from './order-actions'

export const OrderComponent = ({
  order,
  reloadOrder,
  onClose,
  fromPopup,
}: {
  order: OrderDetails
  reloadOrder: () => void
  onClose?: () => void
  fromPopup?: boolean
}) => {
  const mainActions = order.main_actions ?? []
  const bottomActions = order.bottom_actions ?? []
  const allActions = mainActions.concat(bottomActions)

  const assignedDelivery = useMemo(
    () =>
      order.deliveries?.find(
        (d) =>
          ![DeliveryStatus.Pending, DeliveryStatus.Canceled].includes(
            d.delivery_status,
          ),
      ),
    [order.deliveries],
  )

  return (
    <div className='flex h-full flex-1 flex-col'>
      <OrderActions
        order={order}
        delivery={assignedDelivery}
        reloadOrder={reloadOrder}
        onClose={onClose}
        fromPopup
      />

      <div className='h-full overflow-auto p-5'>
        <BasicOrderInfo order={order} delivery={assignedDelivery} />
        <OrderSpecialInstructions order={order} />
        <OrderItems order={order} expanded />
      </div>

      {allActions.length > 0 && (
        <div className='flex flex-col justify-end gap-2.5 border-t border-[#E8E8E8] p-5'>
          <ActionsComponent
            order={order}
            reloadOrder={reloadOrder}
            actions={allActions}
          />
        </div>
      )}
    </div>
  )
}

export const DeliveryDetails = ({
  order,
  delivery,
}: {
  order: Order
  delivery?: OrderDelivery
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        overflow: 'hidden',
      }}
    >
      <Typography variant='h6'>Delivery Details</Typography>
      <Box display='flex' flexWrap='wrap' gap={2}>
        <Box flex={1}>
          <Subtitle>Address</Subtitle>
          <Typography variant='caption'>{order.customer_address}</Typography>
          <br />
          <Typography variant='caption'>{order.customer_phone}</Typography>
        </Box>

        <Box
          flex={1}
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          gap={1}
        >
          {!!order.dropoff_instructions && (
            <>
              <Subtitle>Delivery instructions</Subtitle>
              <Typography variant='caption'>
                {order.dropoff_instructions}
              </Typography>
            </>
          )}
          {!!delivery?.tracking_url &&
            PICKED_UP_STATUSES.includes(delivery.delivery_status) && (
              <Button
                size='small'
                color='secondary'
                variant='outlined'
                component='a'
                target='_blank'
                href={delivery.tracking_url}
              >
                Track Courier
              </Button>
            )}
        </Box>
      </Box>
    </Box>
  )
}

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: 500,
}))
