import { locationSchema } from '@/components/new-autocomplete-address'
import { DateTime } from 'luxon'
import * as z from 'zod'
import { PHONE_REGEX } from '../utils/general'
import { OrderItemSize, VehicleType } from './models'

export const CreateDeliverySchema = z.object({
  dropoff_notification_enabled: z.boolean().default(false).optional(),
  delivery: z
    .object({
      self_delivery: z.boolean().optional(),
      pickup_reference_tag: z.string().optional(),
      pickup_delay_minutes: z.number().int().min(0).optional(),
      pickup_time: z
        .string()
        .transform((s) => {
          return s ? DateTime.fromISO(s).toUTC().toISO() : undefined
        })
        .optional(),
      vehicle_type: z.nativeEnum(VehicleType).default(VehicleType.Car),
      schedule: z.boolean().default(false).optional(),
    })
    .transform((data) => {
      const clean = { ...data }
      if (!data.schedule) {
        clean.pickup_time = DateTime.utc()
          .plus({ minutes: data.pickup_delay_minutes })
          .toISO()
      }
      delete clean['pickup_delay_minutes']

      return clean
    }),
})

export type CreateDelivery = z.infer<typeof CreateDeliverySchema>

export const ItemSchema = z.object({
  name: z.string(),
  quantity: z.number().min(1),
  unit_price: z.number().min(0),
  // dimensions?: { length: number; height: number; depth: number }
  // weight_in_grams?: number
  // vat_percentage?: number
  size: z.nativeEnum(OrderItemSize),
})

export const CreateOrderSchema = z.intersection(
  z.object({
    store_id: z.string(),
    external_id: z.string(),
    order_special_instructions: z.string().optional(),
    courier_pickup_instructions: z.string().optional(),
    dropoff_instructions: z.string().optional(),
    customer_address: locationSchema,
    customer_phone: z.string().regex(PHONE_REGEX, 'Invalid Number!'),
    customer_name: z.string().optional().default('None'),
    contactless_dropoff: z.boolean(),
    is_pickup: z.boolean(),
    items: z.array(ItemSchema).optional(),
    dropoff_notification_enabled: z.boolean().optional(),
  }),
  z.optional(CreateDeliverySchema),
)

export type CreateOrderFields = z.infer<typeof CreateOrderSchema>
