import { Box, Button, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { LiveOrder, PipeStatus } from '../orders/models'
import {
  AsyncFetchWrapperFromState,
  useAsyncFetch,
} from '../components/async-fetch-wrapper'
import { ordersService } from '../orders/order-service'
import { observer } from 'mobx-react'
import { styledWithConditionalProps } from '../utils/styled'
import { v4 as uuid } from 'uuid'
import { useEffectNotFirstTime } from '../utils/hooks'
import { useOrderPopup } from '../order-details/hooks'
import FreeDeliveriesBanner from './free-deliveries-banner'
import { LiveOrdersTablet } from './live-orders-tablet'
import { useStore } from '../root-store'
import CreateOrderDialog from '@/orders/create-order-dialog'
import { colors } from '@/theme'
import { OrderAlertModal } from '@/orders/order-alert-modal'
import { getDelayedPickupTime, isDeliveryDelayed } from '@/orders/utils'
import newOrderSound from '../assets/new-order-sound.mp3'
import delayedDeliverySound from '../assets/delayed_delivery_sound.mp3'

export const LiveOrdersPage = observer(() => {
  const [orders, setOrders] = useState<{ [key in PipeStatus]?: LiveOrder[] }>(
    {},
  )
  const [reloadOrders, setReloadOrders] = useState('')
  const { selectedOrderId } = useOrderPopup()
  const [polling, setPolling] = useState(!selectedOrderId)
  const { authStore, dialogStore, generalStore } = useStore()
  const [newOrders, setNewOrders] = useState([] as LiveOrder[])
  const [newScheduledOrders, setNewScheduledOrders] = useState(
    [] as LiveOrder[],
  )
  const [delayedOrders, setDelayedOrders] = useState([] as LiveOrder[])

  const fetchOrders = async () => {
    const ordersRes = await ordersService.liveOrders()
    setOrders(ordersRes.data.orders)
    setNewOrders(ordersRes.data.newOrders || [])
    setNewScheduledOrders(ordersRes.data.newScheduledOrders || [])
    setDelayedOrders(ordersRes.data.delayedOrders || [])
    if (
      ordersRes.data.newOrders?.length === 0 &&
      ordersRes.data.newScheduledOrders?.length === 0
    ) {
      ordersService.updateLastPoll()
    }
  }

  const clearDelayedOrders = (orderId: string) => {
    setDelayedOrders(delayedOrders.filter((o) => o.id !== orderId))
    ordersService.updateOrderDelay(orderId)
  }

  const clearNewOrders = () => {
    setNewOrders([])
    ordersService.updateLastPoll({ orders: true, schedualOrders: false })
  }

  const clearNewScheduledOrders = () => {
    generalStore.setShowScheduledTab(true)
    setNewScheduledOrders([])
    ordersService.updateLastPoll({ orders: false, schedualOrders: true })
  }

  const { error, isLoading, runAction } = useAsyncFetch({
    loadAction: fetchOrders,
    isInitiallyLoading: true,
  })

  useEffect(() => {
    if (newOrders.length === 0 && newScheduledOrders.length === 0) {
      ordersService.updateLastPoll()
    }
  }, [newOrders.length, newScheduledOrders.length])

  useEffect(() => {
    if (
      generalStore.hasMerchantUpdate &&
      // don't reload when there is an open order
      !selectedOrderId &&
      // don't reload when there are new pending orders
      newOrders.length === 0 &&
      newScheduledOrders.length === 0 &&
      // don't reload when a dialog is open
      Object.keys(dialogStore.customDialogs).length === 0
    ) {
      const lastUpdateRaw = sessionStorage.getItem('last-update')
      const lastUpdate = JSON.parse(lastUpdateRaw || '{}')

      // prevent infinite reloads
      if (
        !lastUpdateRaw ||
        (lastUpdate.hash !== import.meta.env.VITE_A2B_VERSION &&
          Date.now() - lastUpdate.date > 60 * 60 * 1000) // 1 hour
      ) {
        sessionStorage.setItem(
          'last-update',
          JSON.stringify({
            date: Date.now(),
            hash: import.meta.env.VITE_A2B_VERSION,
          }),
        )
        location.reload()
      }
    }
  }, [
    dialogStore.customDialogs,
    generalStore.hasMerchantUpdate,
    newOrders,
    newScheduledOrders,
    selectedOrderId,
  ])

  useEffect(() => {
    setPolling(!selectedOrderId)
  }, [selectedOrderId])

  useEffect(() => {
    runAction()
  }, [reloadOrders, authStore.currentUserStore?.store_id])

  useEffect(() => {
    if (polling) {
      const interval = setInterval(fetchOrders, 5000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [polling])

  useEffectNotFirstTime(() => {
    if (polling) {
      runAction()
    }
  }, [polling])

  return (
    <div className='flex flex-1 flex-col !overflow-visible'>
      <FreeDeliveriesBanner />
      <Root>
        {!selectedOrderId && (
          <>
            {newOrders.length > 0 ? (
              <OrderAlertModal
                orders={newOrders}
                orderAlertVolume={
                  Object.values(orders).flat()[0]?.store?.new_order_alert_volume
                }
                text={`Order${newOrders.length > 1 ? 's' : ''}`}
                subText={`Need${newOrders.length > 1 ? '' : 's'} to be confirmed`}
                subTextColor={'text-white'}
                backgroundColor={'bg-a2b-blue'}
                clearOrders={clearNewOrders}
                sound={newOrderSound}
              />
            ) : newScheduledOrders.length > 0 ? (
              <OrderAlertModal
                orders={newScheduledOrders}
                orderAlertVolume={
                  Object.values(orders).flat()[0]?.store?.new_order_alert_volume
                }
                text={`Scheduled Order${newOrders.length > 1 ? 's' : ''}`}
                subText={`Need${newOrders.length > 1 ? '' : 's'} to be confirmed`}
                subTextColor={'text-black'}
                backgroundColor={'bg-scheduled'}
                clearOrders={clearNewScheduledOrders}
                sound={newOrderSound}
              />
            ) : delayedOrders.length > 0 ? (
              delayedOrders.map((o) => (
                <OrderAlertModal
                  key={o.id}
                  orders={[o]}
                  orderAlertVolume={
                    Object.values(orders).flat()[0]?.store
                      ?.new_order_alert_volume
                  }
                  text={`Delayed pickup`}
                  subText={`#${o.external_id} for ${o.customer_name}`}
                  subTextColor={'text-black'}
                  backgroundColor={'bg-delayed'}
                  clearOrders={clearDelayedOrders}
                  delayTime={getDelayedPickupTime(
                    o.deliveries?.find((delivery) =>
                      isDeliveryDelayed(delivery),
                    ) ?? o.deliveries![0],
                  )}
                  sound={delayedDeliverySound}
                />
              ))
            ) : (
              <></>
            )}
          </>
        )}
        <AsyncFetchWrapperFromState
          isLoading={isLoading}
          error={error}
          reload={runAction}
        >
          <LiveOrdersTablet
            orders={orders}
            reloadOrders={() => setReloadOrders(uuid())}
          />
          {!!authStore.externalCustomer?.name &&
            !Object.values(orders).length && (
              <FirstLoginMessage>
                <div>
                  <Box sx={{ color: 'white' }} textAlign='center'>
                    Welcome to {authStore.externalCustomer!.name}
                  </Box>
                  <Box sx={{ color: 'white' }} textAlign='center'>
                    Local Delivery Portal
                  </Box>
                </div>
                <Button
                  style={{ padding: '15px', backgroundColor: colors.green.G00 }}
                  color='primary'
                  variant='contained'
                  fullWidth={true}
                  onClick={() => {
                    return dialogStore.showDialogAsync(CreateOrderDialog)
                  }}
                >
                  Create Delivery
                </Button>
              </FirstLoginMessage>
            )}
        </AsyncFetchWrapperFromState>
      </Root>
    </div>
  )
})

const FirstLoginMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#414142',
  flexDirection: 'column',
  width: 460,
  borderRadius: '10px',
  height: 300,
  padding: theme.spacing(10),
  gap: 25,
  fontWeight: 700,
  lineHeight: '28px',
  alignSelf: 'center',
  marginTop: '60px',
}))

const Root = styledWithConditionalProps(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  flexGrow: 1,
  overflow: 'hidden',

  [theme.breakpoints.up('md')]: {
    // ...($openPopup
    //   ? {
    //       transition: theme.transitions.create('margin', {
    //         easing: theme.transitions.easing.easeOut,
    //         duration: theme.transitions.duration.enteringScreen,
    //       }),
    //       marginRight: 0,
    //     }
    //   : {
    //       transition: theme.transitions.create('margin', {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.leavingScreen,
    //       }),
    //       marginRight: `-${ORDER_DRAWER_WIDTH}`,
    //     }),
  },
}))
