import DeleteIcon from '@/components/icons/delete-icon'
import { DialogButton, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import {
  ALLOW_CANCEL_DELIVERY,
  OrderStatus,
  RefundStatus,
} from '@/orders/models'
import { RefundDialog } from '@/orders/refunds'
import { useStore } from '@/root-store'
import { CurrencyExchange, SupportAgent } from '@mui/icons-material'
import { Crisp } from 'crisp-sdk-web'
import { useCallback, useMemo } from 'react'
import { StepProps } from '../steps'

export default function IssueWithOrderStep({
  setOpen,
  setCurrentStep,
  order,
  reloadOrder,
}: StepProps) {
  const { dialogStore, snackbarStore } = useStore()
  const disableCancelOrderButton = useMemo(
    () =>
      order.deliveries?.some(
        (d) => !ALLOW_CANCEL_DELIVERY.includes(d.delivery_status),
      ),
    [order],
  )
  const disableRefundRequestButton = useMemo(
    () => ![OrderStatus.Delivered, OrderStatus.Canceled].includes(order.status),
    [order.status],
  )

  const openChat = useCallback(() => {
    setOpen(false)
    Crisp.chat.open()
  }, [setOpen])

  const onRefundSelection = async () => {
    if (!order.total_merchant_charge) {
      snackbarStore.showSnackbar({
        title:
          'Order does not have any a2b fees, maybe it is not Delivered / Canceled yet',
        severity: 'error',
      })
      return
    }

    let refundStatus: RefundStatus | undefined
    if (
      order.refunds?.some((refund) => {
        const statusFound = [
          RefundStatus.Rejected,
          RefundStatus.Approved,
        ].includes(refund.refund_status)
        if (statusFound) {
          refundStatus = refund.refund_status
        }
        return statusFound
      })
    ) {
      snackbarStore.showSnackbar({
        title: `Order already has a refund request in status ${refundStatus?.toLowerCase()}`,
        severity: 'error',
      })
      return
    }

    setOpen(false)

    await dialogStore.showDialogAsync(RefundDialog, {
      order,
      reloadOrder,
    })
  }

  return (
    <>
      <DialogHeader>
        <DialogTitle>Issue With Order</DialogTitle>
      </DialogHeader>
      <div className='flex flex-col gap-2'>
        <DialogButton onClick={() => openChat()}>
          <SupportAgent className='!h-6 !w-6' />
          Chat with support
        </DialogButton>

        <DialogButton
          disabled={disableCancelOrderButton}
          onClick={() => setCurrentStep('cancelOrder')}
        >
          <DeleteIcon className='h-6 w-6' />
          Cancel order
        </DialogButton>
        {disableCancelOrderButton && (
          <p className='-mt-1 mb-1 text-xs text-neutral-400'>
            Your order can not be canceled at this time
          </p>
        )}

        <DialogButton
          disabled={disableRefundRequestButton}
          onClick={async () => {
            await onRefundSelection()
          }}
        >
          <CurrencyExchange className='!h-6 !w-6 p-0.5' />
          Request Refund
        </DialogButton>
        <div className='-mt-1 mb-1 text-xs text-neutral-400'>
          {disableRefundRequestButton
            ? 'Only available for Delivered / Canceled orders'
            : 'Please prepare the receipt of the order'}
        </div>
      </div>
    </>
  )
}
