import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import { useEffect, useState } from 'react'
import { Step, steps } from './steps'
import { Order } from '@/orders/models'

export default function IssueWithOrder({
  reloadOrder,
  order,
}: {
  reloadOrder: () => void
  order: Order
}) {
  const { id: orderId } = order
  const [open, setOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState<Step>('issueWithOrder')
  const StepElement = steps[currentStep]

  useEffect(() => {
    if (!open) {
      setCurrentStep('issueWithOrder')
    }
  }, [open])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <button
          className='rounded border border-neutral-200 p-2 text-xs font-medium text-[#9D9D9D]'
          onClick={(e) => {
            e.stopPropagation()
            setOpen(true)
          }}
        >
          Issue with order
        </button>
      </DialogTrigger>
      <DialogContent
        className='max-w-2xl'
        onClick={(e) => e.stopPropagation()}
        onOverlayClick={(e) => e.stopPropagation()}
      >
        <StepElement
          order={order}
          orderId={orderId}
          reloadOrder={reloadOrder}
          setOpen={setOpen}
          setCurrentStep={setCurrentStep}
        />
      </DialogContent>
    </Dialog>
  )
}
