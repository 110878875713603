import { BaseModel } from '../utils/base-model'
import uberDirectLogo from '/deliveries/uber-direct.png'
import uberEatsLogo from '/ordering-platforms/uber-eats.png'
import grubhubLogo from '/ordering-platforms/grubhub-logo.png'
import doordashDeliveryLogo from '/ordering-platforms/doordash-logo.png'
import doordashOrderLogo from '/ordering-platforms/doordash-logo-square.svg'
import postmatesLogo from '/ordering-platforms/postmates-logo.png'
import sliceLogo from '/ordering-platforms/slice-logo.png'
import chownowLogo from '/ordering-platforms/chownow-logo.svg'
import { StoreModel } from '../stores/models'
import { A2BEvent } from '../events/event-models'
import { upperFirst } from 'lodash'
import { colors } from '../theme'
import { AlertColor } from '@mui/material'
import { DateTime } from 'luxon'

export enum OrderingPlatform {
  Doordash = 'DOORDASH',
  UberEats = 'UBER_EATS',
  Grubhub = 'GRUBHUB',
  Postmates = 'POSTMATES',
  Slice = 'SLICE',
  Chownow = 'CHOWNOW',
  Manual = 'MANUAL',
}

export const OrderingPlatforms: {
  [key in OrderingPlatform]: { displayString: string; icon?: string }
} = {
  [OrderingPlatform.Doordash]: {
    displayString: 'DoorDash',
    icon: doordashOrderLogo,
  },
  [OrderingPlatform.UberEats]: {
    displayString: 'Uber Eats',
    icon: uberEatsLogo,
  },
  [OrderingPlatform.Postmates]: {
    displayString: 'Postmates',
    icon: postmatesLogo,
  },
  [OrderingPlatform.Grubhub]: {
    displayString: 'Grubhub',
    icon: grubhubLogo,
  },
  [OrderingPlatform.Slice]: {
    displayString: 'Slice',
    icon: sliceLogo,
  },
  [OrderingPlatform.Chownow]: {
    displayString: 'ChowNow',
    icon: chownowLogo,
  },
  [OrderingPlatform.Manual]: {
    displayString: 'Manual',
  },
}

export enum OrderStatus {
  Created = 'CREATED',
  Confirmed = 'CONFIRMED',
  Accepted = 'ACCEPTED',
  PendingRejection = 'PENDING_REJECTION',
  Rejected = 'REJECTED',
  Ready = 'READY',
  PickedUp = 'PICKED_UP',
  Delivered = 'DELIVERED',
  PendingCancellation = 'PENDING_CANCELLATION',
  Canceled = 'CANCELED',
}

export const OrderStatuses: {
  [key in OrderStatus]: {
    displayString: string
    longDisplayString?: string
    backgroundColor?: string
    preferDeliveryStatus?: boolean
    severity?: AlertColor
  }
} = {
  [OrderStatus.Created]: {
    displayString: 'Created',
    longDisplayString: 'Order Created',
    backgroundColor: colors.dark.D60,
  },
  [OrderStatus.Confirmed]: {
    displayString: 'Confirmed',
    longDisplayString: 'Order Confirmed',
    backgroundColor: colors.dark.D60,
  },
  [OrderStatus.Accepted]: {
    displayString: 'Accepted',
    backgroundColor: colors.blue.B40,
  },
  [OrderStatus.PendingRejection]: {
    displayString: 'Pending Rejection',
    backgroundColor: colors.red.R30,
    severity: 'error',
  },
  [OrderStatus.Rejected]: {
    displayString: 'Rejected',
    backgroundColor: colors.red.R40,
  },
  [OrderStatus.Ready]: {
    displayString: 'Ready',
    backgroundColor: colors.green.G40,
  },
  [OrderStatus.PickedUp]: {
    displayString: 'Picked Up',
    backgroundColor: colors.green.G40,
  },
  [OrderStatus.Delivered]: {
    displayString: 'Delivered',
    backgroundColor: colors.green.G20,
  },
  [OrderStatus.PendingCancellation]: {
    displayString: 'Pending Cancellation',
    backgroundColor: colors.red.R30,
    severity: 'error',
  },
  [OrderStatus.Canceled]: {
    displayString: 'Canceled',
    backgroundColor: colors.red.R40,
  },
}

export interface Location {
  longitude: number
  latitude: number
  display_string: string
  phone_number: string
}

export interface Order extends BaseModel {
  display_id?: string
  external_id?: string
  store_id: string
  ordering_platform: OrderingPlatform
  status: OrderStatus
  contactless_dropoff: boolean
  order_special_instructions: string
  courier_pickup_instructions: string
  pickup_location: Location
  pickup_name: string

  dropoff_instructions: string
  customer_address?: string
  customer_name: string
  customer_phone: string
  is_pickup: boolean

  order_value: number
  items: OrderItem[]
  subtotals: LineItem[]
  raw_data: any
  store: StoreModel

  last_event?: A2BEvent
  last_status_change_event?: A2BEvent

  alert?: string
  main_actions: (OrderAction | OrderAction[])[]
  secondary_actions: OrderAction[]
  bottom_actions: OrderAction[]
  pending_action?: OrderAction
  is_self_delivery: boolean

  order_placed_at: string

  is_scheduled: boolean
  // the time we need to move the order from scheduled to pending on the merchant portal
  scheduled_prepare_time: string | null

  is_red_alert?: boolean
  red_alert_reason?: string
  red_alert_time?: string

  include_utensils: boolean | null

  customer_requested_pickup_time?: string | null
  customer_requested_dropoff_time?: string | null
  merchant_requested_pickup_time?: string | null

  dropoff_notification_enabled: boolean
  refunds?: OrderRefund[]
  total_merchant_charge?: number
}

export type OrderWithDeliveries = Order & {
  deliveries?: OrderDelivery[]
}

export type OrderDetails = OrderWithDeliveries & { events: A2BEvent[] }

export enum OrderItemSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
}
export const OrderItemSizes = Object.values(OrderItemSize).map((i) => ({
  key: i,
  value: upperFirst(i),
}))

export type OrderItem = {
  id: string
  name: string
  quantity: number
  unit_price?: number
  total_price?: number
  dimensions?: { length: number; height: number; depth: number }
  weight_in_grams?: number
  vat_percentage?: number
  size?: OrderItemSize
  specialInstructions?: string
  label?: string
  options?: string[]
}

export enum LineItemType {
  Subtotal = 'subtotal',
  Tax = 'tax',
  Tip = 'tip',
  DeliveryFee = 'delivery_fee',
  ServiceFee = 'service_fee',
  Other = 'other',
}

export type LineItem = {
  id: string
  name: string
  price?: number
  type: LineItemType
}

export enum ActionType {
  Call = 'call',
  Api = 'api',
  PageUrl = 'page_url',
  Notification = 'notification',
  Custom = 'custom',
}

export enum Action {
  AddPaymentMethod = 'add_payment_method',
  Accept = 'accept',
  AcceptScheduled = 'accept_scheduled',
  Reject = 'reject',
  Cancel = 'cancel',
  SendSMS = 'send_sms',
  CallStore = 'call_store',
  CallSupport = 'call_support',
  CreateDelivery = 'create_delivery',
  UpdatePrepTime = 'update_prep_time',
  MarkReady = 'mark_ready',
  MarkDelivered = 'mark_delivered',
  MarkPickedUp = 'mark_picked_up',
}

export enum InsightsInterval {
  Today = 'day',
  Yesterday = 'yesterday',
  Week = 'week',
  Month = 'month',
  AllTime = 'all_time',
}

export const InsightsIntervals: Record<
  InsightsInterval,
  {
    displayString: string
    isoStart?: () => string
    isoEnd?: () => string
  }
> = {
  [InsightsInterval.Today]: {
    displayString: 'Today',
    isoStart: () => {
      return DateTime.now().startOf('day').toSQL()
    },
  },
  [InsightsInterval.Yesterday]: {
    displayString: 'Yesterday',
    isoStart: () => {
      return DateTime.now().minus({ days: 1 }).startOf('day').toSQL()
    },
    isoEnd: () => {
      return DateTime.now().minus({ days: 1 }).endOf('day').toSQL()
    },
  },
  [InsightsInterval.Week]: {
    displayString: '7 Days',
    isoStart: () => {
      return DateTime.now().minus({ days: 7 }).startOf('day').toSQL()
    },
  },
  [InsightsInterval.Month]: {
    displayString: '30 Days',
    isoStart: () => {
      return DateTime.now().minus({ days: 30 }).startOf('day').toSQL()
    },
  },
  [InsightsInterval.AllTime]: { displayString: 'All Time' },
}

export type OrderAction = {
  action: Action
  type: ActionType
  title: string
  link?: string
  color?: 'primary' | 'secondary' | 'yellow' | 'error'
  variant?: 'outlined'
} & (
  | { type: ActionType.Call; phone: string; link: string }
  | { type: ActionType.PageUrl; link: string }
  | { type: ActionType.Notification; text: string }
  | { type: ActionType.Api; api: string }
  | { type: ActionType.Custom; data?: any }
)

export type LiveOrder = Order & {
  deliveries?: OrderDelivery[]
  pipe_status: PipeStatus

  drag_action?: {
    action: OrderAction
    target_pipe_status: PipeStatus
  }
}

export enum PipeStatus {
  Scheduled = 'Scheduled',
  Pending = 'Pending',
  InProcess = 'In Process',
  Ready = 'Ready',
}

export enum DeliveryPartner {
  DoorDash = 'DOORDASH',
  Uber = 'UBER',
}

export const DeliveryPartners = {
  [DeliveryPartner.DoorDash]: {
    displayString: 'DoorDash',
    icon: doordashDeliveryLogo,
  },
  [DeliveryPartner.Uber]: {
    displayString: 'Uber',
    icon: uberDirectLogo,
  },
}

export enum DeliveryStatus {
  // The delivery has been created
  Created = 'CREATED',
  // The delivery has been created
  Pending = 'PENDING',
  // The delivery has been assigned to a courier
  Confirmed = 'CONFIRMED',
  // The courier is en route to the pick up location
  EnrouteToPickup = 'ENROUTE_TO_PICKUP',
  // The courier is arriving at the pick up location imminently
  ArrivingAtPickup = 'ARRIVING_AT_PICKUP',
  // The courier has picked up the items and is heading to the drop off
  PickedUp = 'PICKED_UP',
  // The courier is en route to the drop off location
  EnrouteToDropoff = 'ENROUTE_TO_DROPOFF',

  // The Dasher is arriving at the drop off location imminently
  ArrivingAtDropoff = 'ARRIVING_AT_DROPOFF',

  // The delivery has been completed
  Delivered = 'DELIVERED',

  // The delivery has been cancelled
  Canceled = 'CANCELED',

  // The delivery has been returned
  Returned = 'RETURNED',
}

export const BEFORE_PICKUP_STATUSES = [
  DeliveryStatus.Pending,
  DeliveryStatus.Confirmed,
  DeliveryStatus.EnrouteToPickup,
  DeliveryStatus.ArrivingAtPickup,
]

export const PICKED_UP_STATUSES = [
  DeliveryStatus.PickedUp,
  DeliveryStatus.ArrivingAtDropoff,
  DeliveryStatus.EnrouteToDropoff,
  DeliveryStatus.Delivered,
]

export const ALLOW_UPDATE_PREP_DELIVERY = [DeliveryStatus.Pending]

export const ALLOW_CANCEL_DELIVERY = [
  DeliveryStatus.Pending,
  DeliveryStatus.Canceled,
]

export const DeliveryStatuses: {
  [key in DeliveryStatus]: {
    displayString: string
    longDisplayString?: string
    backgroundColor?: string
    severity?: AlertColor
  }
} = {
  [DeliveryStatus.Created]: {
    displayString: 'Created',
    longDisplayString: 'Delivery Created',
  },
  [DeliveryStatus.Pending]: {
    displayString: 'Pending Courier Assignment',
    backgroundColor: colors.dark.D60,
  },
  [DeliveryStatus.Confirmed]: {
    displayString: 'Assigned to Driver',
    backgroundColor: 'rgb(191,240,250)',
  },
  [DeliveryStatus.EnrouteToPickup]: {
    displayString: 'Enroute to Pickup',
    backgroundColor: 'rgb(255,250,215)',
  },
  [DeliveryStatus.ArrivingAtPickup]: {
    displayString: 'Arriving at Pickup',
    backgroundColor: 'rgb(255,250,215)',
  },
  [DeliveryStatus.PickedUp]: {
    displayString: 'Picked Up',
    backgroundColor: 'rgb(255,250,215)',
  },
  [DeliveryStatus.EnrouteToDropoff]: {
    displayString: 'Enroute to Dropoff',
    backgroundColor: 'rgb(226,255,215)',
  },
  [DeliveryStatus.ArrivingAtDropoff]: {
    displayString: 'Arriving at Dropoff',
    backgroundColor: 'rgb(226,255,215)',
  },
  [DeliveryStatus.Delivered]: {
    displayString: 'Delivered',
    backgroundColor: 'rgb(175,246,148)',
  },
  [DeliveryStatus.Canceled]: {
    displayString: 'Canceled',
    backgroundColor: colors.red.R40,
  },
  [DeliveryStatus.Returned]: {
    displayString: 'Returned',
    backgroundColor: 'rgb(255,203,127)',
  },
}

export enum VehicleType {
  Car = 'CAR',
  Bicycle = 'BICYCLE',
}

export enum NewOrderAlertVolume {
  Loud = 'LOUD',
  Quiet = 'QUIET',
}

export interface OrderDelivery extends BaseModel {
  id: string
  order_id: string
  delivery_partner: DeliveryPartner
  delivery_status: DeliveryStatus
  requested_pickup_time: string
  pickup_time_estimated: string
  initial_pickup_eta: string
  dropoff_time_estimated: string
  create_delivery_response?: string
  courier_name?: string
  courier_pickup_phone?: string
  tracking_url: string
  vehicle_type: VehicleType
}

export enum RefundStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Rejected = 'REJECTED',
}

export interface OrderRefund extends BaseModel {
  id: string
  order_id: string
  refund_status: RefundStatus
  refund_amount_requested: string
  refund_amount_accepted?: string
  refund_reason: string
  refund_rejected_reason?: string
  created_at: string
  updated_at: string
  receipt_url?: string
}
