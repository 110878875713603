import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { OrderWithDeliveries } from './models'
import { ordersService } from './order-service'
import { Box } from '@mui/material'
import { AsyncFetchWrapper } from '../components/async-fetch-wrapper'
import { OrdersTable } from './orders-table'
import { v4 as uuid } from 'uuid'
import { Section } from './utils'
import { OrderInsights } from './order-insights'
import { useStore } from '../root-store'

export const OrdersPage = observer(() => {
  const [orders, setOrders] = useState<OrderWithDeliveries[]>([])
  const [reloadOrders, setReloadOrders] = useState('')
  const [limit, setLimit] = useState(25)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(-1)
  const { authStore } = useStore()

  const fetchOrders = useCallback(async () => {
    const ordersRes = await ordersService.getOrders(limit, page)
    setTotal(ordersRes.data.total)
    setOrders(ordersRes.data.orders)
  }, [limit, page])

  useEffect(() => {
    setPage(0)
    setTotal(-1)
  }, [authStore.currentUserStore?.store_id])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingTop: 3,
        paddingBottom: 14,
      }}
    >
      <OrderInsights />
      <AsyncFetchWrapper
        loadAction={fetchOrders}
        triggerProp={[
          reloadOrders,
          authStore.currentUserStore?.store_id,
          page,
          limit,
        ]}
      >
        <Section overflow='auto' marginBottom={5} padding={2}>
          <OrdersTable
            pagination={{
              page,
              limit,
              total,
              setPage,
              setLimit,
            }}
            orders={[...orders]}
            reloadOrders={() => setReloadOrders(uuid())}
          />
        </Section>
      </AsyncFetchWrapper>
    </Box>
  )
})
