import {
  DeliveryPartner,
  DeliveryPartners,
  DeliveryStatus,
  OrderingPlatform,
  OrderWithDeliveries,
} from './models'
import { TableCellProps } from '@mui/material/TableCell/TableCell'
import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { DateTime } from 'luxon'
import { styled, SxProps } from '@mui/material/styles'
import { pluralSuffixOptionalString, generateShortName } from '../utils/general'
import { formatPriceStr } from '../utils/price-utils'
import { OrderingPlatformAvatar, OrderStatusComponent } from './components'
import { useOrderPopup } from '../order-details/hooks'
import React from 'react'

// type RowType = { order: Order; delivery?: OrderDelivery; key: string }

export const OrdersTable = ({
  orders,
  pagination,
}: {
  pagination: {
    page: number
    limit: number
    total: number
    setPage: (page: number) => void
    setLimit: (limit: number) => void
  }
  orders: OrderWithDeliveries[]
  reloadOrders: () => void
}) => {
  const { toggleOpenOrder } = useOrderPopup()
  const columns: {
    header: string
    render: (row: OrderWithDeliveries) => React.ReactNode | string | null
    align?: TableCellProps['align']
  }[] = [
    {
      header: 'Order #',
      render: (row) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            <OrderingPlatformAvatar order={row} />#
            {row.display_id ?? row.external_id}
          </Box>
        )
      },
    },
    {
      header: 'Recipient',
      render: (row) => {
        return (
          <TwoLine
            row1={
              <Box>
                {!!row.customer_name && (
                  <span style={{ fontWeight: 600 }}>
                    {generateShortName(row.customer_name)}
                  </span>
                )}
                <span> {row.customer_phone}</span>
              </Box>
            }
            row2={<Box>{row.customer_address}</Box>}
          />
        )
      },
    },
    {
      header: 'Status',
      render: (row) => {
        return (
          <TwoLine
            sx={{ width: 150 }}
            row1={
              <OrderStatusComponent
                order={row}
                delivery={row.deliveries?.[0]}
              />
            }
          />
        )
      },
    },
    {
      header: 'Time',
      render: (row) => {
        return (
          <Box>
            {DateTime.fromISO(row.created_at).toLocaleString(
              DateTime.DATETIME_SHORT,
            )}
          </Box>
        )
      },
    },
    {
      header: 'Courier',
      render: (row) => {
        if (!row.deliveries?.[0]) {
          return null
        }

        return (
          <Box display='flex' gap={2} alignItems='center'>
            {row.deliveries?.[0]?.courier_name
              ? `${row.deliveries?.[0]?.courier_name} ${row.deliveries?.[0].courier_pickup_phone || ''}`.trim()
              : row.deliveries?.[0]?.delivery_status ===
                  DeliveryStatus.Pending && (
                  <span className='loading-dots'>
                    Assigning
                    <br />
                    now
                  </span>
                )}
          </Box>
        )
      },
    },
    {
      header: '$ Amount',
      render: (row) => {
        return row.ordering_platform === OrderingPlatform.Manual
          ? 'MANUAL ORDER'
          : `${formatPriceStr({
              d: row.order_value,
              showZero: true,
            })}${row.items?.length ? ` (${row.items.length} item${pluralSuffixOptionalString(row.items)})` : ''}`
      },
    },
  ]

  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map((c) => {
            return <TableCell key={c.header}>{c.header}</TableCell>
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {orders.map((row) => {
          return (
            <TableRow
              sx={{ cursor: 'pointer' }}
              key={row.id}
              onClick={() => {
                toggleOpenOrder(row.id)
              }}
            >
              {columns.map((c) => {
                return (
                  <TableCell align={c.align} key={c.header}>
                    {c.render(row)}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
      <TableFooter>
        <TablePagination
          count={pagination.total}
          rowsPerPage={pagination.limit}
          onRowsPerPageChange={(event) => {
            pagination.setLimit(Number(event.target.value))
            pagination.setPage(0)
          }}
          page={pagination.page}
          onPageChange={(event, page) => pagination.setPage(page)}
        />
      </TableFooter>
    </Table>
  )
}

const TwoLine = ({
  row1,
  row2,
  sx,
}: {
  row1: React.ReactNode | string
  row2?: React.ReactNode | string
  sx?: SxProps
}) => {
  return (
    <Box sx={sx} display='flex' flexDirection='column' gap={1}>
      <Row1>{row1}</Row1>
      {!!row2 && <Row2>{row2}</Row2>}
    </Box>
  )
}

export const CourierLogo = ({
  deliveryPartner,
}: {
  deliveryPartner: DeliveryPartner
}) => {
  const icon = DeliveryPartners[deliveryPartner].icon
  return (
    <Avatar sx={{ border: '1px solid gray' }}>
      <img src={icon} style={{ width: 40, height: 40 }} />
    </Avatar>
  )
}

const Row1 = styled(Box)(() => ({
  // fontWeight: 600,
}))

const Row2 = styled(Box)(({ theme }) => ({
  // fontWeight: 600,
  fontSize: theme.typography.caption.fontSize,
}))
